import { FC } from 'react';
import ContentLogo, { ContentLogoCoreProps } from '.';
import { AllContentType } from '@utils/types/search';

interface ModelTypeLogoProps extends ContentLogoCoreProps {
    modelType: AllContentType;
}

const ModelTypeLogo: FC<ModelTypeLogoProps> = ({ modelType, ...contentLogoProps }) => (
    <ContentLogo contentType="modelType" contentTypeValue={modelType} {...contentLogoProps} />
);

export default ModelTypeLogo;
