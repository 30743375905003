import { Box, SxProps, Typography } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { sxs } from '@utils/mui/sx';

const shortcutHelper: SxProps = {
    alignItems: 'center',
    display: 'flex',
};

const shortcutIconSx: SxProps = {
    alignItems: 'center',
    bgcolor: 'grey.300',
    border: 1,
    borderColor: 'grey.400',
    borderRadius: 0.5,
    boxSizing: 'border-box',
    color: 'common.white',
    display: 'flex',
    height: 26,
    justifyContent: 'center',
    width: 34,
};

const ShortcutsHelper: FC = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <Box sx={shortcutHelper}>
                <Box sx={shortcutIconSx}>
                    <img alt="enterKey" src="/assets/enterKey.svg" />
                </Box>
                <Typography color="white" pl={1} variant="body2">
                    {t('select')}
                </Typography>
            </Box>
            <Box sx={shortcutHelper}>
                <Box sx={shortcutIconSx}>
                    <img alt="downKey" src="/assets/downKey.svg" />
                </Box>
                <Box ml={0.5} sx={shortcutIconSx}>
                    <img alt="upKey" src="/assets/upKey.svg" />
                </Box>
                <Typography color="white" pl={1} variant="body2">
                    {t('navigate')}
                </Typography>
            </Box>
            <Box sx={shortcutHelper}>
                <Typography sx={sxs([shortcutIconSx, { pb: 0.5, width: 38 }])} variant="body2">
                    esc
                </Typography>
                <Typography color="white" pl={1} variant="body2">
                    {t('close')}
                </Typography>
            </Box>
        </>
    );
};

export default ShortcutsHelper;
