import { Theme, SxProps } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const navBarItemSx: SxProps<Theme> = (t) => ({
    '&:focus-visible': {
        backgroundColor: 'grey.300',
        borderRadius: 'shape.borderRadius',
        color: 'grey.500',
        outline: '1px solid',
        outlineColor: 'grey.400',
        outlineOffset: 0,
    },
    '&:hover ': {
        color: 'grey.500',
    },
    cursor: 'pointer',
    fontWeight: 'fontWeightMedium',
    pb: 2,
    pt: 2,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    [t.breakpoints.up('sm')]: {
        fontSize: 12,
    },
    [t.breakpoints.up('md')]: {
        fontSize: 'body2.fontSize',
    },
});
