import { Languages } from '@livv/models';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Button, Menu, MenuItem, SxProps } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC, useState } from 'react';
import { LOCAL_STORAGE_LANGUAGE } from '@utils/consts/language';
import { sxs } from '@utils/mui/sx';

interface LanguageMenuProps {
    sx?: SxProps;
}

const LanguageMenu: FC<LanguageMenuProps> = ({ sx = {} }) => {
    const { lang } = useTranslation();
    const { asPath } = useRouter();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleChange = (targetLanguage: Languages) => {
        window.localStorage.setItem(LOCAL_STORAGE_LANGUAGE, targetLanguage);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                aria-controls="avatar-menu"
                aria-haspopup="true"
                endIcon={<KeyboardArrowDownOutlined />}
                onClick={handleClick}
                sx={sxs([
                    {
                        '&:hover': {
                            backgroundColor: 'grey.300',
                        },
                        color: 'common.white',
                        fontSize: { tablet: 'body1.fontSize', xs: 'body2.fontSize' },
                    },
                    sx,
                ])}
            >
                {lang.toUpperCase()}
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                onClose={handleClose}
                open={Boolean(anchorEl)}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                }}
                keepMounted
            >
                {Object.values(Languages).map((language) => (
                    <Link key={language} href={asPath} locale={language}>
                        <MenuItem
                            onClick={() => {
                                handleChange(language);
                                handleClose();
                            }}
                        >
                            {language.toUpperCase()}
                        </MenuItem>
                    </Link>
                ))}
            </Menu>
        </>
    );
};

export default LanguageMenu;
