import Box from '@mui/material/Box';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import DesktopNestedMenu from './DesktopNestedMenu';
import NavBarLink from '@components/NavBar/NavBarLink';
import { NavigationItem } from '@utils/navigation';

interface LinksProps {
    navigationItems: NavigationItem[];
}

const Links: FC<LinksProps> = ({ navigationItems }) => {
    const { t } = useTranslation('navBar');

    return (
        <Box component="ul" display="flex" gap={{ tablet: 4, xs: 2 }}>
            {navigationItems.map((navigationItem) => {
                const { isExternalLink, isFreeAccess, isMenu, title } = navigationItem;
                // User link has no title
                if (!title) {
                    return null;
                }

                if (isMenu === true) {
                    return (
                        <li key={`${title}_menu}`}>
                            <DesktopNestedMenu navigationMenu={navigationItem} />
                        </li>
                    );
                }

                const { href } = navigationItem;

                return (
                    <li key={`${title}_${href}`}>
                        <NavBarLink
                            data-test-id={`${title}-nav-link`}
                            href={href}
                            isFreeAccess={isFreeAccess}
                            title={title}
                            {...(isExternalLink && { target: '_blank' })}
                        >
                            {t(`link.${title}`)}
                        </NavBarLink>
                    </li>
                );
            })}
        </Box>
    );
};

export default Links;
