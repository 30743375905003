import { useEffect, useRef } from 'react';
import { MENU_ITEM_ID, SELECTED_MENU_ITEM_ID } from '../consts';
import theme from '@styles/theme';

const isHover = (element: Element) => element?.parentElement?.querySelector(':hover') === element;

const addSelection = (element: HTMLElement, scrollIntoView?: boolean) => {
    // eslint-disable-next-line prefer-destructuring
    element.style.background = theme.palette.common.black;
    element.style.outline = `1px solid ${theme.palette.grey[200]}`;
    const enterIcon = element.querySelector('[aria-label="enter"]');
    if (enterIcon) {
        (enterIcon as HTMLElement).style.visibility = 'visible';
    }
    element.classList.add(SELECTED_MENU_ITEM_ID);
    if (scrollIntoView) {
        element.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
        });
    }
};

const removeSelection = (element: HTMLElement) => {
    // eslint-disable-next-line prefer-destructuring
    element.style.background = theme.palette.grey[100];
    element.style.outline = 'none';
    element.classList.remove(SELECTED_MENU_ITEM_ID);
    const enterIcon = element.querySelector('[aria-label="enter"]');
    if (enterIcon) {
        (enterIcon as HTMLElement).style.visibility = 'hidden';
    }
};

interface UseShortcutsProps {
    onClose: () => void;
    onOpen: () => void;
}

/**
 * Open -- Meta + K or Meta + k for MacOS, Ctrl + K or Ctrl + k for Windows / Linux
 * Arrows Up / Down -- to select one element at a time in the menu list
 * Enter -- open an element (equivalent to a click)
 * Esc -- close the modal is supported
 *
 * Shortcut selection works as following:
 * - Only on element must be selected at a time. Selection is not a focus, only visual highlight.
 * - When modal is open, selection must be on the first line after up or down arrow is pressed.
 * - When selection is moved with keys, mouse hover over an element must deactivate key selection and
 * instead create a new selection on a given element under the mouse pointer.
 * - When a selection is created with a mouse on an element, key arrow press should deactivate mouse selection on
 * and element and activate a selection on a neighboring element in a respective direction.
 * - After query modification, if a selected element still remains in a list, its selection shall persists.
 * - Close of modal on Escape key is not implemented here as it is supported by default at MuiDialog
 */
const useShortcuts = ({ onClose, onOpen }: UseShortcutsProps): void => {
    const ref = useRef<number>(0);

    const handleHover = (event: MouseEvent) => {
        const { clientY } = event;

        // addressing known issue scrollIntoView triggers mouseover https://stackoverflow.com/questions/26179043/unwanted-mouse-events-when-scrolling-content-by-javascript
        // if mouse Y coordinates does not change abort
        if (ref.current === clientY) return;
        ref.current = clientY;

        const menuItems = Array.from(
            document.getElementsByClassName(MENU_ITEM_ID),
        ) as HTMLElement[];

        // hover style is not applied to element where style modified by DOM properties
        // and it required manual style setting using methods in addSelection
        menuItems.forEach((item) => {
            if (isHover(item)) {
                addSelection(item as HTMLElement);
            } else {
                removeSelection(item as HTMLElement);
            }
        });
    };

    const handleShortcuts = (event: KeyboardEvent): void => {
        const { metaKey, ctrlKey, key } = event;

        if ((ctrlKey || metaKey) && ['k', 'K'].includes(key)) {
            event.stopPropagation();
            event.preventDefault();
            onOpen();
        } else if (['ArrowUp', 'ArrowDown'].includes(key)) {
            const menuItems = Array.from(
                document.getElementsByClassName(MENU_ITEM_ID),
            ) as HTMLElement[];
            if (!menuItems.length) return;

            // if nothing selected, select 1st menu item
            if (
                !menuItems.filter((item) => item?.classList.contains(SELECTED_MENU_ITEM_ID)).length
            ) {
                addSelection(menuItems[0] as HTMLElement);

                return;
            }

            if (key === 'ArrowDown') {
                for (const [index, item] of menuItems.entries()) {
                    if (
                        (item as HTMLElement).classList.contains(SELECTED_MENU_ITEM_ID) &&
                        index + 1 < menuItems.length
                    ) {
                        removeSelection(item as HTMLElement);
                        addSelection(menuItems[index + 1] as HTMLElement, true);

                        break;
                    }
                }
            } else if (key === 'ArrowUp') {
                for (const [index, item] of menuItems.entries()) {
                    if ((item as HTMLElement).classList.contains(SELECTED_MENU_ITEM_ID) && index) {
                        removeSelection(item as HTMLElement);
                        addSelection(menuItems[index - 1] as HTMLElement, true);
                        // prevents arrows to control cursor position in the input field
                        event.stopPropagation();
                        event.preventDefault();

                        break;
                    }
                }
            }
        } else if (key === 'Enter') {
            event.stopPropagation();
            const selectedItemArray = Array.from(
                document.getElementsByClassName(SELECTED_MENU_ITEM_ID),
            ) as HTMLElement[];
            if (selectedItemArray?.length) {
                (selectedItemArray[0] as HTMLElement).click();
                onClose();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('mouseover', handleHover);

        return () => window.removeEventListener('mouseover', handleHover);
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleShortcuts);

        return () => window.removeEventListener('keydown', handleShortcuts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useShortcuts;
