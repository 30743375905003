import Box from '@mui/material/Box';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import AvatarMenu from './AvatarMenu';
import { ButtonLink } from '@components/Links';
import { useAuth } from '@utils/context/auth';
import useUser from '@utils/context/user';
import { useAmplitude } from '@utils/hooks';
import { TrackingEventName } from '@utils/types/amplitude/events';

const UserMenu: FC = () => {
    const { logout } = useAuth();
    const { info: user, privileges } = useUser();
    const { t } = useTranslation('navBar');
    const { track } = useAmplitude();

    const isPaidCustomer = privileges?.isPaidCustomer;
    const isTrialPeriod =
        typeof privileges?.trialEndDate === 'number' && privileges?.trialEndDate > Date.now();
    const isTrialEnded =
        typeof privileges?.trialEndDate === 'number' && privileges?.trialEndDate <= Date.now();

    const shouldShowSubscribeButton = !isPaidCustomer && (isTrialPeriod || isTrialEnded);

    const handleSubscribeButtonClick = async () => {
        await track({
            name: TrackingEventName.HEADER_NAVIGATION_CLICKED,
            params: {
                item: 'subscribe',
            },
        });
    };

    return (
        <Box alignItems="center" display="flex">
            <AvatarMenu
                firstName={user?.firstName ?? ''}
                lastName={user?.lastName ?? ''}
                logout={logout}
            />
            {shouldShowSubscribeButton && (
                <ButtonLink
                    data-test-id="subscribe-button"
                    href="/offer"
                    onClick={handleSubscribeButtonClick}
                    sx={{ ml: 3 }}
                    variant="contained"
                >
                    {t('subscribe')}
                </ButtonLink>
            )}
        </Box>
    );
};

export default UserMenu;
