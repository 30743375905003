import { LawScope, ModelType } from '@livv/models';
import { Grid, SxProps, Typography } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import ContentLogoImage, { ContentType, ContentTypeValue } from './ContentLogoImage';
import { lawScopeColors, modelTypeColor } from '@styles/theme';
import { AllContentType } from '@utils/types/search';

const getColorHex = (
    contentType: ContentType,
    contentTypeValue: ContentTypeValue,
): {
    color?: string;
    backgroundColor?: string;
} => {
    switch (contentType) {
        case 'modelType':
            return (
                modelTypeColor[contentTypeValue as AllContentType] ||
                modelTypeColor[ModelType.ARTICLE]
            );
        case 'lawScope':
            return { color: lawScopeColors[contentTypeValue as LawScope] };
        default:
            return {};
    }
};

const getNamespace = (contentType: ContentType): string => {
    switch (contentType) {
        case 'modelType':
            return 'modelType';
        default:
            return 'common';
    }
};

export interface ContentLogoCoreProps {
    displayVertically?: boolean;
    label?: string;
    hideText?: boolean;
    sx?: SxProps;
}

interface ModelTypeLogoProps extends ContentLogoCoreProps {
    contentType: 'modelType';
    contentTypeValue: AllContentType;
}

interface LawScopeLogoProps extends ContentLogoCoreProps {
    contentType: 'lawScope';
    contentTypeValue: LawScope;
}

type ContentLogoProps = ModelTypeLogoProps | LawScopeLogoProps;

const ContentLogo: FC<ContentLogoProps> = ({
    contentType,
    contentTypeValue,
    displayVertically = false,
    label,
    hideText = false,
    sx,
}) => {
    const { t } = useTranslation(getNamespace(contentType));
    const colors = getColorHex(contentType, contentTypeValue);

    const isModelType = contentType === 'modelType';

    return hideText ? (
        <ContentLogoImage
            colorsHex={colors}
            contentType={contentType}
            contentTypeValue={contentTypeValue}
        />
    ) : (
        <Grid
            alignItems="center"
            direction={displayVertically ? 'column' : 'row'}
            gap={displayVertically ? 0.75 : 1}
            sx={sx}
            container
        >
            <ContentLogoImage
                colorsHex={colors}
                contentType={contentType}
                contentTypeValue={contentTypeValue}
                large={displayVertically}
            />
            <Typography
                component="span"
                sx={[
                    {
                        fontSize: displayVertically ? 12 : 14,
                        fontWeight: 'medium',
                        textAlign: 'center',
                    },
                    isModelType && { color: colors.color },
                ]}
            >
                {label ?? t(`${contentType === 'lawScope' ? 'lawScope.' : ''}${contentTypeValue}`)}
            </Typography>
        </Grid>
    );
};

export default ContentLogo;
