import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC, useMemo } from 'react';
import { navBarItemSx } from './navBarItemSx';
import { sxs } from '@utils/mui/sx';
import { NavigationLink } from '@utils/navigation';

interface ButtonNestedMenuProps {
    isOpen: boolean;
    links: NavigationLink[];
    menuTitle: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonNestedMenu: FC<ButtonNestedMenuProps> = ({ isOpen, links, menuTitle, onClick }) => {
    const { t } = useTranslation('navBar');
    const router = useRouter();

    const isActive = useMemo(
        () => links.map(({ href }) => href).includes(router.pathname),
        [links, router.pathname],
    );

    return (
        <Typography
            data-test-id={`${menuTitle}-button-nested-menu`}
            onClick={onClick}
            sx={sxs([
                navBarItemSx,
                {
                    color: isActive ? 'white' : 'grey.400',
                    cursor: 'pointer',
                    display: 'flex',
                    fontWeight: 'medium',
                    p: 0,
                },
            ])}
        >
            {t(`link.${menuTitle}`)}
            <ArrowDropDownIcon
                sx={[
                    { mt: '-1px', transition: 'transform 0.5s' },
                    isOpen && { transform: 'rotateZ(-180deg)' },
                ]}
            />
        </Typography>
    );
};

export default ButtonNestedMenu;
