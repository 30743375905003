import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTranslation from 'next-translate/useTranslation';
import nookies from 'nookies';
import { FC, useEffect, useState } from 'react';
import { HelperString } from './consts';

const sx = {
    desktopSearchIcon: {
        ml: { tablet: 0.5, xs: 1 },
        mr: { tablet: 1.25, xs: 0.5 },
        width: 20,
    },
    placeholder: {
        textAlign: 'left',
        width: '100%',
    },
    root: {
        '&:focus-visible': {
            outline: (theme: Theme) => `1px solid ${theme.palette.grey[300]}`,
        },
        '&:hover': {
            backgroundColor: 'grey.100',
            borderColor: 'grey.400',
        },
        alignItems: 'center',
        backgroundColor: 'common.black',
        borderColor: `grey.300`,
        borderRadius: 0.5,
        borderStyle: 'solid',
        borderWidth: 1,
        boxSizing: 'border-box',
        color: 'grey.500',
        cursor: 'pointer',
        display: 'flex',
        height: (theme: Theme) => theme.spacing(4.5),
        justifyContent: 'space-between',
        transition: 'background-color 0.2s ease, border 0.2s ease',
        width: (theme: Theme) => ({ lg: 230, tablet: theme.spacing(14), xs: '100%' }),
    },
    shortcutHelper: {
        backgroundColor: 'grey.100',
        borderColor: 'grey.300',
        borderRadius: 0.5,
        borderStyle: 'solid',
        borderWidth: 1,
        color: 'common.white',
        p: '2px 5px 1px 5px',
        whiteSpace: 'nowrap',
        width: 'auto',
    },
    tabletSearchIcon: {
        color: 'grey.500',
    },
};

const getHelperString = (clientOs?: string): string => {
    if (!clientOs) {
        return '';
    }

    if (clientOs.startsWith('mac')) {
        return HelperString.MACOS;
    }

    return HelperString.WIN;
};

interface ButtonProps {
    clientOs?: string;
    onClick: () => void;
}

const Button: FC<ButtonProps> = ({ clientOs, onClick }) => {
    const { t } = useTranslation('common');
    const [helper, setHelper] = useState(getHelperString(clientOs));
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));

    useEffect(() => {
        // Navigator is unknown server-side, so the helper must be set client-side if cookies did not contain client OS
        if (!helper) {
            nookies.set(undefined, 'clientOS', navigator.platform, { path: '/' });
            // platform is used because new specification's navigator.userAgentData is not supported by all browsers
            // use platform till another method like userAgentData is available in all browsers
            setHelper(getHelperString(navigator.platform));
        }
        // Only check OS on first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Typography
            component="button"
            data-test-id="quicksearch-button"
            onClick={onClick}
            sx={sx.root}
            type="button"
        >
            <SearchIcon sx={sx.desktopSearchIcon} />
            {!isLarge && (
                <Box sx={sx.placeholder}>
                    <Typography variant="body2">{t('quickSearch.button.placeholder')}</Typography>
                </Box>
            )}
            {!isTablet && helper && (
                <Typography sx={sx.shortcutHelper} variant="body2">
                    {helper}
                </Typography>
            )}
        </Typography>
    );
};

export default Button;
