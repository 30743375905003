import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC, MouseEvent, useState } from 'react';

interface AvatarMenuProps {
    firstName: string;
    lastName: string;
    logout: (redirect?: boolean) => void;
}

const avatarSx = {
    backgroundColor: 'grey.600',
    color: 'text.secondary',
    fontSize: { tablet: 'body1.fontSize', xs: 'body2.fontSize' },
    fontWeight: 'fontWeightBold',
    height: { md: 40, xs: 32 },
    width: { md: 40, xs: 32 },
};

const AvatarMenu: FC<AvatarMenuProps> = ({ firstName, lastName, logout }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation('navBar');
    const router = useRouter();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout(false);
        handleClose();
    };

    return (
        <>
            <IconButton
                aria-controls="avatar-menu"
                aria-haspopup="true"
                data-test-id="avatar-menu"
                onClick={handleClick}
                size="large"
                sx={{ ml: 2, p: 0 }}
            >
                <Avatar sx={avatarSx}>
                    {firstName?.charAt(0).toUpperCase()}
                    {lastName?.charAt(0).toUpperCase()}
                </Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                id="avatar-menu"
                onClose={handleClose}
                open={Boolean(anchorEl)}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                }}
                keepMounted
            >
                <MenuItem
                    data-test-id="profile-button"
                    onClick={() => {
                        router.push('/profile');
                        handleClose();
                    }}
                >
                    {t('myProfile')}
                </MenuItem>
                <MenuItem data-test-id="logout-button" onClick={handleLogout}>
                    {t('logout')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default AvatarMenu;
