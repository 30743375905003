import { SearchableRecordInstantSearch } from '@livv/models';
import { getHref } from '@livv/utils/helpers';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { Configure, useInfiniteHits } from 'react-instantsearch';
import { ModelTypeLogo } from '../preview';
import Suggestions from '@components/search/algolia/SearchBox/Suggestions';

const HITS_PER_PAGE = 10;

interface CustomHitsQuickSearchProps {
    hitsPerPage?: number;
}

const CustomHitsQuickSearch: FC<CustomHitsQuickSearchProps> = ({ hitsPerPage = HITS_PER_PAGE }) => {
    const { t } = useTranslation('common');
    const { hits, results } = useInfiniteHits<SearchableRecordInstantSearch>();

    return (
        <>
            <Configure
                // TODO: remove this component when libraries update fixes the issue. Internally types have all necessary props
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hitsPerPage={hitsPerPage}
                restrictSearchableAttributes={['title', 'subtitle', 'analyses.title']}
            />
            <Suggestions
                colorVersion="dark"
                items={
                    results?.query?.trim()
                        ? hits.map((hit) => {
                              const { modelType, subtitle, title } = hit;

                              return {
                                  Icon: <ModelTypeLogo modelType={modelType} hideText />,
                                  href: getHref(hit),
                                  subtitle: [t(`modelType:${modelType}`), subtitle]
                                      .filter(Boolean)
                                      .join(' • '),
                                  title,
                              };
                          })
                        : []
                }
                searchQuery={results?.query || ''}
            />
        </>
    );
};

export default CustomHitsQuickSearch;
