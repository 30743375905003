import { LawScope } from '@livv/models';
import { Box, SxProps } from '@mui/material';
import { FC } from 'react';
import { sxs } from '@utils/mui/sx';
import { AllContentType } from '@utils/types/search';

export type ContentType = 'modelType' | 'lawScope';
export type ContentTypeValue = AllContentType | LawScope;
interface Colors {
    color?: string;
    backgroundColor?: string;
}

const getLogoSrc = (contentType: ContentType, contentTypeValue: ContentTypeValue): string => {
    switch (contentType) {
        case 'modelType':
            return `/assets/modelTypeLogos/${contentTypeValue}Icon.svg`;
        case 'lawScope':
            return `/assets/lawScopeLogo/${contentTypeValue.toUpperCase()}.svg`;
        default:
            return '';
    }
};

const getContainerSx = (size: number, colorsHex: Colors): SxProps => ({
    '&:before': {
        borderRadius: 0.5,
        content: '""',
        height: size,
        position: 'absolute',
        width: size,
        zIndex: 0,
    },
    borderRadius: 0.5,
    display: 'flex',
    fontSize: 14,
    fontWeight: 'medium',
    position: 'relative',
    ...colorsHex,
});

const getImageSx = (size: number): SxProps => ({
    backgroundColor: 'none',
    height: size,
    p: 0,
    width: size,
    zIndex: 0,
});

interface ContentLogoImageProps {
    colorsHex: Colors;
    contentType: ContentType;
    contentTypeValue: AllContentType | LawScope;
    large?: boolean;
    sx?: SxProps;
}

const ContentLogoImage: FC<ContentLogoImageProps> = ({
    colorsHex,
    contentType,
    contentTypeValue,
    large = false,
    sx = {},
}) => {
    const isModelType = contentType === 'modelType';
    const size = large ? 40 : 22;

    return (
        <Box sx={sxs([getContainerSx(size, colorsHex), sx])}>
            <Box
                alt=""
                component="img"
                data-test-id="content-logo-image"
                src={getLogoSrc(contentType, contentTypeValue)}
                sx={sxs([
                    getImageSx(size),
                    isModelType
                        ? {
                              p: large ? '6px' : '3px',
                          }
                        : {},
                ])}
            />
        </Box>
    );
};

export default ContentLogoImage;
