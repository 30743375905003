import { Box, Dialog, DialogContent, DialogTitle, SxProps } from '@mui/material';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import ShortcutsHelper from './ShortcutsHelper';
import CustomHitsQuickSearch from '@components/search/algolia/CustomHitsQuickSearch';
import AlgoliaSearchBox from '@components/search/algolia/SearchBox';
import InstantSearch from '@components/search/InstantSearch';
import { INDEX_NAME } from '@utils/consts/search';

const dialogPaperSx: SxProps = {
    background: 'none',
    maxHeight: 600,
    maxWidth: 840,
    position: 'absolute',
    top: 110,
};

const sharedSx: SxProps = {
    bgcolor: 'grey.100',
    border: 2,
    borderColor: 'grey.300',
};

const dialogTitleSx: SxProps = {
    ...sharedSx,
    borderBottom: 0,
    borderRadius: '8px 8px 0px 0px',
    display: 'flex',
    px: 0,
    py: 2,
};

const footerContainerSx: SxProps = {
    ...sharedSx,
    alignItems: 'center',
    borderRadius: '0px 0px 8px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    px: 4,
    py: 1.5,
};

interface ModalProps {
    onClose: () => void;
    open: boolean;
}

const Modal: FC<ModalProps> = ({ onClose, open }) => {
    const { t } = useTranslation('common');
    const router = useRouter();

    return (
        <Dialog
            PaperProps={{
                sx: dialogPaperSx,
            }}
            data-test-id="quicksearch-modal"
            onClose={onClose}
            open={open}
            fullWidth
        >
            <InstantSearch disableRouting>
                <DialogTitle data-test-id="quicksearch-search-bar" sx={dialogTitleSx}>
                    <AlgoliaSearchBox
                        colorVersion="dark"
                        onSearchClick={(searchValue) =>
                            router.push(`/search?${INDEX_NAME}[query]=${searchValue}`)
                        }
                        placeholder={t('algoliaSearchBox.modal.placeholder')}
                        autoFocus
                        withShortcutHelper
                    />
                </DialogTitle>
                <DialogContent sx={{ ...sharedSx, borderBottom: 0, p: 2 }}>
                    <CustomHitsQuickSearch />
                </DialogContent>
                <Box sx={footerContainerSx}>
                    <ShortcutsHelper />
                </Box>
            </InstantSearch>
        </Dialog>
    );
};

export default Modal;
