import { Menu, MenuItem } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { FC, useState } from 'react';
import ButtonNestedMenu from '../ButtonNestedMenu';
import NavBarLink from '../NavBarLink';
import { NavigationMenu } from '@utils/navigation';

interface DesktopNestedMenuProps {
    navigationMenu: NavigationMenu;
}

const DesktopNestedMenu: FC<DesktopNestedMenuProps> = ({
    navigationMenu: { links, title: menuTitle },
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { t } = useTranslation('navBar');

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ButtonNestedMenu
                isOpen={Boolean(anchorEl)}
                links={links}
                menuTitle={menuTitle ?? ''}
                onClick={handleClick}
            />
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                id={`${menuTitle}-menu`}
                onClose={handleClose}
                open={null !== anchorEl}
                sx={{ p: 1 }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                }}
                keepMounted
            >
                {links.map(({ isExternalLink, isFreeAccess, href, title }) => (
                    <MenuItem key={`${title}_${href}`} sx={{ p: 0 }}>
                        <NavBarLink
                            key={href}
                            data-test-id={`${title}-nav-link`}
                            href={href}
                            isFreeAccess={isFreeAccess}
                            onClick={handleClose}
                            style={{ width: '100%' }}
                            sx={{
                                '&:hover': {
                                    color: 'grey.800',
                                },
                                alignItems: 'center',
                                display: 'flex',
                                p: 1,
                            }}
                            title={title}
                            {...(isExternalLink && { target: '_blank' })}
                        >
                            {t(`link.${title}`)}
                        </NavBarLink>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default DesktopNestedMenu;
